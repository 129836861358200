// noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSCheckFunctionSignatures

const OPACITY_ACTIVE = 1.0;
const OPACITY_INACTIVE = 0.3;
const COMMENT_READ_MORE_TRIGGER_HEIGHT = 198;
const COOKIE_LIFETIME = 999;

let lang = 'en';
let specXHRLoadID = '';
let staticTooltipCount = 0;
let overlayOpen = false;
let specXHR = false;
let commentXHR = false;
let commentQuoteXHR = false;
let commentReportXHR = false;
let skillActionbar = null;
let skillActionbarSlot = null;

let searchFaction = $('#searchFaction');
let searchRace = $('#searchRace');
let searchClass = $('#searchClass');
let skillTreeName = $('#skillTreeName');

(function() {

  lang = document.documentElement.lang;

  $('select').chosen({
    no_results_text: langArr['choosen.no_results_text'],
    placeholder_text_multiple: langArr['choosen.placeholder_text_multiple'],
    placeholder_text_single: langArr['choosen.placeholder_text_single'],
  });

  $('input[type="checkbox"]').esoCheckbox();
  $('input[type="radio"]').esoRadio();

  if(typeof changeTab !== 'undefined') {
    changeSkillTab(changeTab);
  }

  if(searchFaction.length > 0) {
    setSearchFaction(searchFaction.val());
  }

  if(searchRace.length > 0) {
    setSearchRace(searchRace.val());
  }

  if(searchClass.length > 0) {
    setSearchClass(searchClass.val());
  }

  $('#searchFilterHead').on('click', function() {

    let searchFilterBody = $('#searchFilterBody');
    let searchFilterToggle = $('#searchFilterToggle');

    if(searchFilterBody.is(':visible')) {
      searchFilterBody.hide();
      searchFilterToggle.html(searchFilterToggle.data('text-show') + ' <i class="fa fa-arrow-circle-down"></i>');
    }
    else {
      searchFilterBody.show();
      searchFilterToggle.html(searchFilterToggle.data('text-hide') + ' <i class="fa fa-arrow-circle-up"></i>');
    }
  });

  $('img.search_faction').on('mousedown', function(event) {

    // Set default button if no button type set
    if(typeof event.button === 'undefined') {
      event.button = 0;
    }

    // Left click
    if(event.button === 0) {
      setSearchFaction($(this).data('faction'));
    }

    // Right click
    else if(event.button === 2) {
      unsetSearchFaction($(this));
    }
  });

  $('img.search_class').on('mousedown', function(event) {

    // Set default button if no button type set
    if(typeof event.button === 'undefined') {
      event.button = 0;
    }

    // Left click
    if(event.button === 0) {
      setSearchClass($(this).data('class'));
    }

    // Right click
    else if(event.button === 2) {
      unsetSearchClass($(this));
    }
  });

  $('img.search_race').on('mousedown', function(event) {

    // Set default button if no button type set
    if(typeof event.button === 'undefined') {
      event.button = 0;
    }

    // Left click
    if(event.button === 0) {
      setSearchRace($(this).data('race'));
    }

    // Right click
    else if(event.button === 2) {
      unsetSearchRace($(this));
    }
  });

  $('#overlayClose, div.overlay').on('click', closeOverlay);

  $(document).on('keyup', function(e) {
    if(overlayOpen === true && e.key === 'Escape') {
      closeOverlay();
    }
  });

  $('#specLike, div.like_overview').on('click', specLike);

  $('textarea.banner_code, #shortLink').on('focus', function(e) {
    e.target.select();
    $(e.target).one('mouseup', function(e) {
      e.preventDefault();
    });
  });

  commentParseReadMore();

  $('[data-hide-cookie-disclosure]').on('click', function() {
    $(this).parent().remove();
    setCookie('cookie_disclosure', '1', COOKIE_LIFETIME);
  });
})();

/**
 * Opens an overlay with the given Id.
 *
 * @param {string} id The Id of the overlay.
 */
function openOverlay(id) {
  overlayOpen = true;
  $('#' + id).fadeIn('fast');
}

/**
 * Closes the opened overlay.
 */
function closeOverlay() {
  $('div.overlay').fadeOut('fast');
  overlayOpen = false;
}

/**
 * Changes the My Skillfactory tab.
 *
 * @param {string} id The Id of the button object.
 */
function changeSkillTab(id) {

  // Variablen initialisieren
  let thisObj = $('#' + id);
  let tabID = thisObj.data('tab');

  if(id === 'btnSpecInputs') {
    $('#noticeBox').show();
  }
  else {
    $('#noticeBox').hide();
  }

  $('.spec_btn').removeClass('btn_active');
  thisObj.addClass('btn_active');
  $('.spec_tab').hide();
  $('#' + tabID).show();

  $('#activeTab').val(id);
}

/**
 * Sets the search faction value.
 *
 * @param {string} factionID The faction Id.
 */
function setSearchFaction(factionID) {

  if(factionID === '') {
    return;
  }

  $('img.search_race').css('opacity', OPACITY_INACTIVE);

  $('img.search_faction').css('opacity', OPACITY_INACTIVE);
  $('img[data-faction="' + factionID + '"]').css('opacity', OPACITY_ACTIVE);
  searchFaction.val(factionID);
}

/**
 * Resets the search faction selection.
 *
 * @param {string} obj The clicked object.
 */
function unsetSearchFaction(obj) {

  if(parseInt(obj.data('faction')) === parseInt(searchFaction.val())) {
    $('img.search_faction').css('opacity', OPACITY_INACTIVE);
    searchFaction.val('');
  }
}

/**
 * Sets the search class value.
 *
 * @param {string} classID The class Id.
 */
function setSearchClass(classID) {

  if(classID === '') {
    return;
  }

  $('img.search_class').css('opacity', OPACITY_INACTIVE);
  $('img[data-class="' + classID + '"]').css('opacity', OPACITY_ACTIVE);
  searchClass.val(classID);
}

/**
 * Resets the search class selection.
 *
 * @param {string} obj The clicked object.
 */
function unsetSearchClass(obj) {

  if(parseInt(obj.data('class')) === parseInt(searchClass.val())) {
    $('img.select_class').css('opacity', OPACITY_INACTIVE);
    searchFaction.val('');
  }
}

/**
 * Sets the search race value.
 *
 * @param {string} raceID The race Id.
 */
function setSearchRace(raceID) {

  if(raceID === '') {
    return;
  }

  $('img.search_race').css('opacity', OPACITY_INACTIVE);
  $('img[data-race="' + raceID + '"]').css('opacity', OPACITY_ACTIVE);
  searchRace.val(raceID);
}

/**
 * Resets the search race selection.
 *
 * @param {string} obj The clicked object.
 */
function unsetSearchRace(obj) {

  if(parseInt(obj.data('race')) === parseInt(searchRace.val())) {
    $('img.select_race').css('opacity', OPACITY_INACTIVE);
    searchRace.val('');
  }
}

/**
 * Sets the like action for a build.
 */
function specLike() {

  if(specXHR === false) {

    specXHR = true;

    let likeBox = $(this);
    let id = likeBox.data('spec');
    let likesObj = likeBox.children('span');

    if(id > 0) {

      specXHRLoadID = 'specLoad' + id;
      likeBox.hide();
      likeBox.before('<i id="' + specXHRLoadID + '" class="fa fa-spinner fa-spin" title="' + likeBox.data('text-loading') + '"></i>');

      $.getJSON(
        '/ajax/spec_like.php?lang=' + lang,
        { id: id }
      )
      .done(function(json) {

        if(json.status === 1) {
          likeBox.css('opacity', '0.3');
          likesObj.html(json.result);
          likeBox.finish().animate({
            opacity: 1.0
          }, 800);
        }
        else {
          showStaticTooltip('specID' + id, likeBox, '<i class="fa fa-info-circle"></i>&nbsp;&nbsp;' + json.result, 3000, 38, 'center');
        }
      })
      .fail(function(jqxhr, textStatus, error) {
        showStaticTooltip('specID' + id, likeBox, '<i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;Request Failed:<br/>' + textStatus + ', ' + error, 3000, 38, 'center');
      })
      .always(function() {
        specXHR = false;
        $('#' + specXHRLoadID).remove();
        specXHRLoadID = '';
        likeBox.show();
      });
    }
  }
}

/**
 * Adds a comment.
 *
 * @param {string} type The type of the comment.
 * @param {string} srcID The source Id of the comment.
 * @param {boolean} fullWidth If True the comment is displayed in full with otherwise False.
 */
function commentSubmit(type, srcID, fullWidth) {

  if(commentXHR === false) {

    commentXHR = true;

    let commentError = $('#commentErrorBox');
    let commentLoadingBox = $('#commentLoadingBox');
    let commentText = $('#commentText');
    let commentCount = $('#commentCount');
    let commentList = $('#commentList');
    let commentBtn = $('#commentBtn');
    let commentCounter = parseInt(commentCount.html());

    commentText.attr('disabled', 'disabled');
    commentBtn.attr('disabled', 'disabled');
    commentLoadingBox.show();
    commentCounter++;

    $.ajax({
      url: '/ajax/add_comment.php?lang=' + lang,
      type: 'post',
      dataType: 'json',
      data: { type: type, id: srcID, text: commentText.val(), counter: commentCounter, full_width: fullWidth },
    })
    .done(function(json) {

      if(json.status === 1) {

        commentText.val('');

        if(json.needs_review === 0) {

          // Append comment to list
          commentList.prepend(json.result);

          if (commentCounter === 1) {
            $('#noCommentsMsg').remove();
          }

          commentCount.html(commentCounter);

          commentText.val('');
          commentError.hide();

          commentParseReadMore();

          $('html, body').animate({scrollTop: commentList.offset().top}, 400);
        }
        else {
          commentError.html(json.needs_review_text);
          commentError.show();
        }
      }
      else {
        commentError.html(json.result);
        commentError.show();
      }
    })
    .fail(function(jqxhr, textStatus, error) {
      commentError.html('<div class="error width_auto"><ul><li>XHR error: ' + error + '</li></ul></div>');
      commentError.show();
    })
    .always(function() {
      commentXHR = false;
      commentLoadingBox.hide();
      commentText.prop('disabled', false);
      commentBtn.prop('disabled', false);
    });
  }
}

/**
 * Displays a static tooltip.
 *
 * @param {string|null} customID Sets the tooltip Id or False if the Id should be automatically generated.
 * @param {object} obj The object to which the tooltip should be aligned.
 * @param {string} content The tooltip content.
 * @param {int} closeDelay The numbe rof miliseconds until the tooltip is closed.
 * @param {int} xAdjustment	The number of pixels that should be added to the margin-left value.
 * @param {string} classes Additional CSS classes that should be added to the tooltip container.
 */
function showStaticTooltip(customID, obj, content, closeDelay, xAdjustment, classes) {

  if(customID === null) {
    staticTooltipCount++;
    customID = 'staticTooltip' + staticTooltipCount;
  }

  let tooltipObj = $('#' + customID);

  if(tooltipObj.length === 0) {

    obj.before('<div id="' + customID + '" class="tooltip tooltip_static' + ((classes !== '') ? ' ' + classes : '') + '">' + content + '</div>');

    tooltipObj.css('margin-top', '-' + (tooltipObj.height() + 32) + 'px');
    tooltipObj.css('margin-left', (parseInt(tooltipObj.css('margin-left').replace('px', '')) + xAdjustment) + 'px');

    // Fadeout
    let timeout = setTimeout('removeStaticTooltip({ data: {id: \'' + customID + '\', timeout: null }});', closeDelay);

    // Pass timeout handler to cancel the timeout on closing
    tooltipObj.on('click', {id: customID, timeout: timeout}, removeStaticTooltip);
  }
}

/**
 * Removes the static tooltip.
 *
 * @param {object} event The event data.
 */
function removeStaticTooltip(event) {

  if(event.data.timeout !== null) {
    clearTimeout(event.data.timeout);
  }

  let tooltipObj = $('#' + event.data.id);

  tooltipObj.off('click');
  tooltipObj.fadeOut('fast');
  tooltipObj.remove();
}

/**
 * Adds a read more label to comments that exceeded the max text length.
 */
function commentParseReadMore() {

  $('div.comment_text').each(function(key, val) {

    let obj = $(val);
    let parent = obj.parent();

    if(obj.data('crm') === undefined && obj[0].scrollHeight > COMMENT_READ_MORE_TRIGGER_HEIGHT) {
      obj.data('crm', 1);
      parent.prepend('<div class="read_more_button" style="width:' + obj.width() + 'px; margin-top:' + (obj.height() + 49) + 'px;" onclick="commentReadMore(this);"><i class="fa fa-chevron-down"></i> ' + obj.data('text-read-more') + ' <i class="fa fa-chevron-down"></i></div>');
    }
  })
}

/**
 * Adds a show less label to comments that exceeded the max text length.
 *
 * @param {object} obj The jQuery object.
 */
function commentParseReadLess(obj) {

  let parent = obj.parent();

  if(obj.data('crl') === undefined) {
    obj.data('crl', 1);
    parent.prepend('<div class="read_less_button" style="width:' + obj.width() + 'px; margin-top:' + (obj[0].scrollHeight + 49) + 'px;" onclick="commentReadMore(this);"><i class="fa fa-chevron-up"></i> ' + obj.data('text-collapse') + ' <i class="fa fa-chevron-up"></i></div>');
  }
}

/**
 * Toggles the comment read more panel.
 *
 * @param {object} handle The handle of the calling object.
 */
function commentReadMore(handle) {

  let obj = $(handle).parent().children('div.comment_text');
  let parent = obj.parent();

  if(obj.height() === COMMENT_READ_MORE_TRIGGER_HEIGHT) {
    parent.children('div.read_more_button').hide();
    obj.animate({maxHeight: obj[0].scrollHeight}, 400, function() {
      commentParseReadLess(obj);
      parent.children('div.read_less_button').show();
    });
  }
  else {
    parent.children('div.read_less_button').hide();
    obj.animate({maxHeight: COMMENT_READ_MORE_TRIGGER_HEIGHT}, 400, function(){
      parent.children('div.read_more_button').show();
    });
  }
}

// noinspection JSUnusedGlobalSymbols
/**
 * Fetches the comment text of the given comment Id and adds it as quoted text to the comment textarea.
 *
 * @param {int} commentID The Id of the comment.
 */
function commentQuote(commentID) {

  if(commentQuoteXHR === false) {

    commentQuoteXHR = true;

    $.ajax({
      url: '/ajax/get_comment_quote.php?lang=' + lang,
      type: 'post',
      dataType: 'json',
      data: { id: commentID },
    })
    .done(function(json) {
      $('#commentText').val(json.result);
    })
    .always(function() {

      commentQuoteXHR = false;

      $('html, body').animate({ scrollTop: $('#commentHead').offset().top }, 400);
    });
  }
}

// noinspection JSUnusedGlobalSymbols
/**
 * Reports a comment.
 *
 * @param {int} commentID The Id of the comment.
 */
function commentReport(commentID) {

  if(commentReportXHR === false) {

    commentReportXHR = true;

    $.ajax({
      url: '/ajax/comment_report.php?lang=' + lang,
      type: 'post',
      dataType: 'json',
      data: { id: commentID },
    })
    .done(function(json) {
      alert(json.result);
    })
    .always(function() {
      commentReportXHR = false;
    });
  }
}

/**
 * Sets the a cookie.
 *
 * @param	{string} key The name of the cookie.
 * @param	{string} value DeThe value fo the cookie.
 * @param	{number} expire The TTL of the cookie.
 */
function setCookie(key, value, expire) {
  let date = new Date();
  date.setDate(date.getDate() + expire);
  document.cookie = key + '=' + escape(value) + ';path=/;expires=' + date.toGMTString();
}

/**
 * Disabled or enables the chosen select with the givne selector.
 *
 * @param {string} selector Der Selektor.
 * @param {boolean} disable Wenn True wird das Feld deaktiviert, bei False wird es aktiviert.
 */
function disableChosenSelect(selector, disable) {
  $(selector).prop('disabled', disable).trigger('chosen:updated');
}
